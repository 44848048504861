import React from "react"
import Layout from "../../components/layout"
import Map from "../../components/map/map"
import Newsletter from "../../components/newsletter/newsletter"
import { Container, Row, Col, Navbar, NavbarBrand, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Alert, FormText } from 'reactstrap'
import { graphql } from 'gatsby'
import { signIn, getTeams, updateTeam, getImageURL, uploadImage, sendResetEmail } from '../../services/team'
import { faHandPointUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import md5 from "md5"
import uuidv1 from 'uuid'
import Helmet from 'react-helmet'
import AdSense from 'react-adsense';
import './softball-map.css'

class LocalTeamsPage extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            token: null,
            email: '',
            password: '',
            signInError: '',
            open: false,
            loadingTeams: false,
            savingTeam: false,
            teams: [],
            selectedTeamId: -1,
            unsavedChanges: false,
            showSignOutButton: false,
            logoEditInput: '',
            signingIn: false,
            forgotPassword: false,
            resetEmail: '',
            forgotEmailSent: false,
            forgotEmailSending: false
        };


    }

    async componentDidMount() {

        if (localStorage.getItem("token")) {
            this.setState({token: localStorage.getItem("token")});            
        }
    }

    render() {

        const data = this.props.data
        const teams = data.allTeam.nodes;
        const stateList = data.allState.nodes;

        let { token } = this.state

        var accountId = null;
        if (this.state.teams.length > 0) {
            accountId = this.state.teams[0].AccountId;
        }

        let states = data.allState.nodes.map((s) => {
            return <Col xs={6} sm={6} md={4} lg={2} key={s.name}><a href={"/softball-map/" + s.slug} style={{ whiteSpace: "nowrap" }}>{s.name}</a> </Col>;
        })


        return (
            <Layout>
                <Helmet
                    title="Map Of Fastpitch Softball Teams"
                    meta={[
                        { name: 'description', content: "Is your team on the map?  Our softball map is the place to go to find teams in your area" },
                        { name: 'og:image', content: 'http://fastpitchhub.com/images/map.jpg' }
                    ]}
                />

                <div className="wrapper">
                    <Container>

                        {!token &&
                            <Row className="d-none d-md-block">
                                <Col style={{ textAlign: "right" }}>
                                    <span className="signinPrompt">Log In To Manage Your Teams <FontAwesomeIcon width={0} style={{ fontSize: "30px" }} icon={faHandPointUp} /></span>
                                </Col>
                            </Row>

                        }


                        <Row style={{ marginTop: "25px" }}>
                            <Col>
                                <Navbar color="dark" dark>
                                    <NavbarBrand className="mr-auto" style={{ color: "white" }}>Softball Map</NavbarBrand>
                                </Navbar>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Map teams={teams} states={stateList} accountId={accountId} />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "25px" }}>
                            <Col><Newsletter /></Col>
                        </Row>

                        <Row>
                            <Col>

                                <AdSense.Google
                                    client='ca-pub-7485372285264586'
                                    slot='8690934422'
                                    style={{ display: 'block', margin: "0 auto", width: "100%", height: "auto" }}
                                    format='auto'
                                    responsive='true'
                                />

                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Row style={{ backgroundColor: "white", padding: "10px 20px", marginTop: "50px", borderTop: "4px solid #343a40" }} noGutters>
                                    <Row>
                                        <Col><h3>Teams By State</h3></Col>
                                    </Row>

                                    <Row>
                                        {states}
                                    </Row>

                                </Row>

                            </Col>
                        </Row>

                    </Container>



                </div>


            </Layout>
        )
    }


}

export default LocalTeamsPage

export const query = graphql`
{  
    allTeam {
        nodes {
            teamId
            accountId
            id
            name
            age
            description
            coach
            address
            city
            state
            zip
            latitude
            longitude
            icon
            contactEmail
            facebook
            twitter
            instagram
            validated
        }                  
    }
    allState {
        nodes {
            id
            name
            abbr
            slug
            nearby {
                name
                abbr
                slug
            }
        }                  
    }
}
`